export const environment = {
  production: true,
  envName: 'UAT',
  baseURL:
    'https://1qanfpdqbc.execute-api.ap-southeast-2.amazonaws.com/uat/api/',
  UserPoolId: 'ap-southeast-2_nuOcIPpL6',
  ClientId: '2b39vkjolepe7p3dn70rf86rk4',
  LocationPoolId: 'ap-southeast-2:12d37ce0-2762-44a4-9982-6e3506df9c4e',
  LocationIndexName: 'FPGeolocationservice',
  CaptchaSiteKey: '6Le6e-UZAAAAACauNcVypMhqlTQn08H_I3bgctGz',
  CaptchaSecretKey: '6Le6e-UZAAAAAHkm3BfslVJ7Z72M3xa4_fEluNhX',
  TermAndCondionJsonURL:
    'https://s3-ap-southeast-2.amazonaws.com/fitpassdev-fileupload/TermAndCon.txt',
  IdleTime: 900,
  MapsAPIKey: 'AIzaSyB0SHZK3ngwOu0r26fm3pOrhKumXS7XdHY',
  NCKXXL: '1950277964321',
  ENVKEY: '3',
  TemplateTax:
    'https://s3-ap-southeast-2.amazonaws.com/fitpassuat-fileupload/assets/taxinvoice.pdf',
  NCKXXL2: '7061737323313288',
  NCKXXL3: '7061447324413244',
  GoogleAnalyticsCode: 'UA-50241741-2',
  imageS3Url: 'https://s3.ap-southeast-2.amazonaws.com/fitpassuat-fileupload/',
  imageCFUrl: 'https://d1aio9541isutj.cloudfront.net/',
  cognitoDomainUrl: 'fp-uat.auth.ap-southeast-2.amazoncognito.com',
  cognitoRedirectUrl: 'https://uat.fitnesspassport.com.au/login',
  cognitoUserPoolId: 'ap-southeast-2_5d3JD6ybL',
  cognitoUserPoolClientId: '2qm7svsjvm2j3158a3t1evs7su',
  cognitoIdentityPoolId: 'ap-southeast-2:60d7c592-36ee-446f-8ff6-83646c8b32fc',
  AzureADIndetityProviderName: 'AzureAD',
  logoutUrl: 'https://uat.fitnesspassport.com.au/login',
  devcycleKey: 'dvc_client_672ea79e_9ae8_4b3e_862f_24f0f11240ad_4e32a0d',
  sentryDSN:
    'https://f405ff3c6880ba0ca326d7c967e647bb@o4507982156726272.ingest.us.sentry.io/4508006333808640',
  sentryTracesSampleRate: 0.1,
  sentryEnvironment: 'uat', // mapping to match BE
  datadog: {
    enabled: true,
    trackUserInteractions: true,
    applicationId: '9804e176-b1e6-4008-b513-c98c213b997c',
    clientToken: 'pub5067f7f648eec5ef544caff5f6811345',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    service: 'legacyfrontend',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    defaultPrivacyLevel: 'mask-user-input' as const,
    env: 'uat',
  },
};